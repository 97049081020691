.footer {
  &__container {
    width: 100%;

    background-color: var(--semidark);
    padding: 1.5rem 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
  }

  &__content {
    display: flex;
    flex-direction: row;
    max-width: 90%;
  }

  &__website-rights {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    margin: auto 15px;
  }
}

/* Copyright */

@media screen and (max-width: 820px) {
  .footer {
    &__content {
      flex-direction: column;
      max-width: 90%;
    }

    &__website-rights {
      margin: 10px auto;
    }
  }
}
