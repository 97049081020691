// NAVBAR ARCHITECTURE

.navbar {
  position: fixed;
  height: 80px;
  width: 100%;
  color: var(--dark);
  background-image: url("./assets/images/background2.jpg");
  background-size: contain;

  &__container {
    padding-top: 1vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__menu {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 1rem;

    &__left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 30%;
    }

    &__right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 30%;
      list-style-type: none;
    }
  }
}

// STYLING NAVBAR MENU ITEMS

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 2rem;
  height: 100%;

  font-weight: 500;
  color: var(--dark);

  font-weight: 350;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;

  &:hover {
    transition: 0.2s ease-out;
    transform: scale(1.3);
  }
}

.nav-item {
  height: 100%;
  border-bottom: 2px solid transparent;
}

//   .nav-item:hover {
//     border-bottom: 2px solid var(--outlined);
//   }

// STYLING NAVBAR TITLE/LOGO

.navbar {
  &__icon {
    margin: 0.5rem;
    font-size: 2.5rem;
    color: var(--dark);
  }
}

// MOBILE MENUS

.mobilemenu {
  &__navbar {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .nav-links {
    padding: 0rem 1.1rem;
  }
}

@media screen and (max-width: 960px) {
  .mobilemenu {
    &__navbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      font-size: 1.8rem;
      cursor: pointer;
      width: 100%;
      align-items: center;
      padding: 0 2rem;
      border-radius: 0 0 25px 25px;
    }

    &__logo,
    &__title {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__button {
      color: var(--dark);
    }
  }

  .navbar {
    &__menu__left {
      display: none;
    }

    &__menu__right {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      width: 100%;
      height: 30vh;
      position: absolute;
      top: 80px;
      left: -150%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    &__menu.active {
      background-image: url("./assets/images/background2.jpg");
      background-size: contain;

      border-radius: 0 0 25px 25px;

      left: 0%;
      padding: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  }

  .nav {
    &-links {
      height: 4rem;
      justify-content: flex-end;
      margin-right: 1rem;
    }

    &-item:hover {
      background-color: var(--white);
    }

    &-links:hover {
      color: var(--green);
    }
  }
}
